import React from "react"

import Layout from "../components/layout"
import CutContainer from "../components/cutContainer"
import { greenGradient, grayGradient2 } from "../services/constants"
import styles from "./contact.module.scss"
import TextArea from "../components/textArea"
import Input from "../components/input"
import Button from "../components/button"
import locationPin from "../images/common/location_pin.webp"
import Image from "gatsby-image"
import EmailInput from "../components/emailInput"
import { graphql, Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import globalStyles from "../services/globalStyles.module.scss"
import Translate from "../components/translate";
import Context from "../components/contextProvider";
import Message from "../components/message";
import { langPath } from "../services/languageService"
import { CONTACT_FORM_GUID, trackHubspotFormData } from "../utils/trackHubspotFormData"

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMessage: false,
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    var usp = new URLSearchParams(document.location.search);
    this.state = {
      ...this.state,
      name: usp.get('name'),
      email: usp.get('email'),
      message: usp.get('message'),
    }
  }

  handleSubmit = (e, setCinema) => {
    e.preventDefault();
    const form = e.target;
    const { success_title, success_subtitle } = this.props.data.texts._.contact_message;

    trackHubspotFormData(CONTACT_FORM_GUID, {
      email: this.state.email,
      name: this.state.name,
      message: this.state.message,
    })

    fetch("https://i7sjujgi2e.execute-api.us-east-1.amazonaws.com/contactEmail", {
      method: "POST",
      body: JSON.stringify({
        "location": window.location.href,
        ...this.state
      })
    })
      .then(() => {
        setCinema({
          cinemaOpen: true,
          cinemaContent: <Message title={success_title} subtitle={success_subtitle} />
        })
        form.reset()
      }
      )
      .catch(error => alert(error));
  };
  render() {
    const { flags, texts: { _ } } = this.props.data
    const languageCode = this.props.pageContext && this.props.pageContext.lang ? this.props.pageContext.lang : "en";
    const link = langPath(languageCode);

    const sorted = flags.edges.sort((a, b) => {
      return ("" + a.node.name).localeCompare(b.node.name)
    })

    return (
      <Layout header={<Header num={1} translations={_} headerFullYOffset={1000} mobileHeaderFullYOffset={100} fixed whiteFont languageCode={languageCode}/>} translations={_} languageCode={languageCode}>
        <CutContainer
          style={{
            background: greenGradient,
            position: "relative",
          }}
          sizeOfCut={2}
          styleClass={styles.cutContainer}
        >
          <h1 className={styles.header}>{_.contact.let_us_know}</h1>
          <Context.Consumer>
            {({ setCinema }) => (
              <form
                name="contact-form-with-name-and-message"
                method="POST"
                className={styles.contactContainer}
                onSubmit={(e) => { this.handleSubmit(e, setCinema) }}>
                <input
                  type="hidden"
                  name="form-name"
                  value="contact" />
                <Input
                  onChange={this.handleChange}
                  classStyle={styles.inputStyle}
                  placeholder={_.enter_your_name}
                  value={this.state.name}
                  name="name"
                  type="text"
                />
                <Input
                  onChange={this.handleChange}
                  classStyle={styles.inputStyle}
                  placeholder={_.enter_your_email}
                  value={this.state.email}
                  name="email"
                  type="email"
                />
                <TextArea
                  name="message"
                  type="text"
                  style={{ height: 150, textAlign: "" }}
                  classStyle={styles.inputStyle}
                  placeholder={_.message}
                  value={this.state.message}
                  onChange={this.handleChange}
                />
                <Button
                  type="submit"
                  title={_.contact_message.success_title}
                  subtitle={_.contact_message.success_subtitle}
                  showMessage={this.state.showMessage}
                  hideOverlay={() => { this.setState({ showMessage: false }) }}>
                  {_.contact.send_message}
                </Button>
              </form>
            )}
          </Context.Consumer>
        </CutContainer>
        <div className={styles.departmentContacts}>
          <div className={styles.departmentContact}>
            <h1 className={styles.departmentContactHeader}>{_.contact.get_support}</h1>
            <p className={styles.departmentContactParagraph}>
              {_.contact.get_support_paragraph}:
            </p>
            <a
              href="mailto:support@sidelinesports.com"
              className={[styles.departmentContactEmail, globalStyles.hyperlink].join(' ')}
            >
              support@sidelinesports.com
            </a>
          </div>
          <div className={styles.departmentContact}>
            <h1 className={styles.departmentContactHeader}>{_.contact.talk_sales}</h1>
            <p className={styles.departmentContactParagraph}>
              {_.contact.talk_sales_paragraph}:
            </p>
            <a
              href="mailto:sales@sidelinesports.com"
              className={[styles.departmentContactEmail, globalStyles.hyperlink].join(' ')}
            >
              sales@sidelinesports.com
            </a>
          </div>
          <div className={styles.departmentContact}>
            <h1 className={styles.departmentContactHeader}>{_.contact.everything_else}</h1>
            <p className={styles.departmentContactParagraph}>
              {_.contact.everything_else_paragraph}:
            </p>
            <a
              href="mailto:info@sidelinesports.com"
              className={[styles.departmentContactEmail, globalStyles.hyperlink].join(' ')}
            >
              info@sidelinesports.com
            </a>
          </div>
        </div>
        <CutContainer
          style={{
            background: grayGradient2,
            position: "relative",
          }}
          styleClass={styles.localContacts}
          sizeOfCut={2}
          topCut
          bottomCut
        >
          <h1 className={styles.localContactHeader}>
            {_.contact.contact_distributor}
          </h1>
          <div className={styles.localContactsContainer}>
            {sorted.map((val, i) => {/* stupid, using file system to reuse name for countries and email in display. Use .md file to join images and info */
              const splitName = val.node.name.split("-")
              const names = splitName.map((val, index) => {
                if (val !== "flag" && val.length > 0) {
                  val = val.charAt(0).toUpperCase() + val.slice(1)
                  return val
                }
                return null
              })
              let name = names.join(" ")
              let email =
                names
                  .map(val => {
                    if (val) {
                      return val.trim().toLowerCase()
                    }
                    return null
                  })
                  .join("") + "@sidelinesports.com"

              if (name.trim() === "Czech Republic") {
                email = "cze@sidelinesports.com"
              }
              if(name.trim() === "United Kingdom"){
                email = "uk@sidelinesports.com"
              }
              if(name.trim() === "Usa"){
                name = name.toUpperCase();
              }
              return (
                <div key={i} className={styles.localContactContainer}>
                  <div className={styles.circleContainer}>
                    <Image
                      fluid={val.node.childImageSharp.fluid}
                      className={styles.avatar}
                    />
                  </div>
                  <h1 className={styles.localName}>{name}</h1>
                  <a href={"mailto:" + email}
                    className={[styles.localContact, globalStyles.hyperlink].join(' ')}>
                    {email}
                  </a>
                </div>
              )
            })}
          </div>
        </CutContainer>
        <div className={styles.bottomNoteContainer}>
          <div className={styles.bottomNoteImageContainer}>
            <img className={styles.bottomNoteImage} src={locationPin} alt="" />
          </div>

          <div className={styles.bottomNoteTextContainer}>
            <div style={{ marginBottom: 50 }}>
              <span className={styles.bottomNoteTitle}><Translate template={_.contact.become_a_local_distributor}>
                <span
                  style={{ color: "#1eb3fc" }}
                />
              </Translate>
              </span>
            </div>
            <div className={styles.bottomNoteParagraph}>
              <Translate template={_.contact.local_dist_paragraph}>
                <Link to={link('/contact')} className={globalStyles.hyperlink} style={{ color: "#1276d6" }} />
              </Translate>
            </div>
          </div>
        </div>
        <div className={styles.emailInput}>
          <EmailInput
            title={_.ready_to_start}
            placeholder={_.enter_your_email}
            buttonText={_.start_free_trial}
            translations={_.contact_message}
          />
        </div>
        <Header num={2} translations={_} whiteFont className={globalStyles.hideOnMobile} seeThrough headerFullYOffset={1000} languageCode={languageCode}/>
        <Footer translations={_} languageCode={languageCode}/>
      </Layout>
    )
  }
}

export default ContactPage

export const query = graphql`
  query Contact($langFile: String) {
    site{
      siteMetadata{
        ip_stack_key
      }
    }
    texts: file(relativePath: {eq: $langFile}) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        enter_your_name
        enter_your_email
        ready_to_start
        enter_your_email
        start_free_trial
        message
        contact {
          let_us_know
          send_message
          get_support
          get_support_paragraph
          talk_sales
          talk_sales_paragraph
          everything_else
          everything_else_paragraph
          contact_distributor
          become_a
          become_a_local_distributor
          local_distributor
          local_dist_paragraph
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
    flags: allFile(filter: { relativePath: { glob: "flags/*" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 55, maxHeight: 55) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
