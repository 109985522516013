import React from "react"
import PropTypes from "prop-types"
import styles from "./emailInput.module.scss"
import Button from "./button"
import Input from "./input"
import { greenGradient } from "../services/constants"
import { graphql } from "gatsby"
import Context from "./contextProvider"
import Message from "./message";


function encode(data) {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
		.join("&");
}

class EmailInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			success: false,
			posting: false,
		}
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e, set) => {
		e.preventDefault();
		const form = e.target;
		set({
			signUpOpen: true
		})
		form.reset();
		setTimeout(() => {
			XPSSignup['_email'](form.querySelectorAll('input')[1].value);
			document.querySelectorAll('input#name')[0].focus();
		}, 5)
	};

	render() {
		const { title, placeholder, buttonText, noBackground, translations } = this.props;
		const { success, error, posting } = this.state;
		if (noBackground) {
			return (
				<Context.Consumer>
					{({ data, set }) => (
						<form
							name="contact-form-with-email"
							method="POST"
							className={styles.emailInputBackground}
							onSubmit={(e) => this.handleSubmit(e, set)}
							action="/success">
							<input type="hidden" name="form-name" value="contact" />
							<div className={styles.content}>
								{title && <h1 className={styles.header}>{title}</h1>}

								<div className={styles.formContainer}>
									<Input placeholder={placeholder} classStyle={styles.input} type="email" name="email" onChange={this.handleChange} />
									<Button classStyle={styles.button} type="submit">{buttonText}</Button>
								</div>
							</div>
						</form>
					)}
				</Context.Consumer>
			)
		}
		return (
			<Context.Consumer>
				{({ data, set }) => (
					<form
						name="contact-form-with-email"
						method="POST"
						style={{ background: greenGradient }}
						className={styles.emailInputBackground}
						onSubmit={e => this.handleSubmit(e, set)}
						action="/success">
						<input
							type="hidden"
							name="form-name"
							value="contact" />
						<div className={styles.content}>
							{title && <h1 className={styles.header}>{title}</h1>}

							<div className={styles.formContainer}>
								<Input placeholder={placeholder} classStyle={styles.input} type="email" name="email" onChange={this.handleChange} />
								<Button classStyle={styles.button} type="submit">{buttonText}</Button>
							</div>
						</div>
					</form>
				)}
			</Context.Consumer>
			)
	}
}

EmailInput.propTypes = {
	title: PropTypes.string,
	placeholder: PropTypes.string,
	buttonText: PropTypes.string
}


export default EmailInput

export const query = graphql`
fragment EmailTranslations on TextsYaml{
	contact_message {
		success_title
		success_subtitle
	}
}
`
