import React from "react"
import PropTypes from "prop-types"
import styles from "./cutContainer.module.scss"

class CutContainer extends React.Component {
  constructor(props){
    super(props)
    this.state={
      mobile: false
    }
  }

  componentDidMount(){
    this.setState({mobile: document.documentElement.clientWidth < 810});
  }

  render() {
    const { children, sizeOfCut, left, style, styleClass, topCut, bottomCut, reverse, grayBottom, leftAndRight, zIndex, zIndexAfter, zIndexBefore} = this.props;
    let styleClassModified = styleClass;
    if (!Array.isArray(styleClass)) {
      styleClassModified = [styleClass];
    }
    if(zIndex){
      styleClassModified.push(styles.zIndex)
    }
    if(zIndexAfter){
      styleClassModified.push(styles.zIndexAfter)
    }
    if(zIndexBefore){
      styleClassModified.push(styles.zIndexBefore)
    }

    switch (sizeOfCut) {
      case 1:
        const smallStyle = leftAndRight && !this.state.mobile ? styles.smallLeftAndRight : (left ? styles.smallLeft : styles.smallRight);
        const smallTopStyle = leftAndRight && !this.state.mobile ? styles.smallTopLeftAndRight : ((!reverse && left) || (reverse && !left) ? styles.smallTopLeft : styles.smallTopRight);

        if (topCut && !bottomCut) {
          return <div style={{ ...style }} className={[smallTopStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}> {children} </div>;
        }
        if (topCut && bottomCut) {
          return <div style={{ ...style }} className={[smallStyle, smallTopStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}> {children} </div>;
        }
        return <div style={{ ...style }} className={[smallStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}>{children}</div>;
      case 2:
        const midStyle = leftAndRight && !this.state.mobile ? styles.midLeftAndRight : (left ? styles.midLeft : styles.midRight);
        const midTopStyle = leftAndRight && !this.state.mobile ? styles.midTopLeftAndRight : ((!reverse && left) || (reverse && !left) ? styles.midTopLeft : styles.midTopRight);
        if (topCut && !bottomCut) {
          return <div style={{ ...style }} className={[midTopStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}> {children} </div>;
        }
        if (topCut && bottomCut) {
          return <div style={{ ...style }} className={[midStyle, midTopStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}> {children} </div>;
        }
        return <div style={{ ...style }} className={[midStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}> {children} </div>;
      case 3:
        const bigStyle = leftAndRight && !this.state.mobile ? styles.bigLeftAndRight : (left ? styles.bigLeft : styles.bigRight);
        const bigTopStyle = leftAndRight && !this.state.mobile ? styles.bigTopLeftAndRight : ((!reverse && left) || (reverse && !left) ? styles.bigTopLeft : styles.bigTopRight);
        if (topCut && !bottomCut) {
          return <div style={{ ...style }} className={[bigTopStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}> {children} </div>;
        }
        if (topCut && bottomCut) {
          return <div style={{ ...style }} className={[bigStyle, bigTopStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}> {children} </div>;
        }
        return <div style={{ ...style }} className={[bigStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}>{children} </div>;
      default:

        return <div style={{ ...style }} className={[styles.midRight, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}>{children}</div>;
    }
  }
}

CutContainer.propTypes = {
  sizeOfCut: PropTypes.number,
  left: PropTypes.bool,
  style: PropTypes.object,
}

export default CutContainer
