

export const purpleGradient = "linear-gradient(0deg, #C140AC 5%, #003E75 100%)";
export const greenGradient =  "linear-gradient(336.54deg, #6CC971 -12.07%, #006D9C 41.21%, #000F2D 110.88%)";
export const greenGradient2 = "linear-gradient(122.96deg, #77CC00 -5.77%, #D7DF21 108.04%)";
export const grayGradient = "linear-gradient(224.15deg, #FFFFFF 1.62%, #F5F6F8 99.51%), #FFFFFF";
export const grayGradient2 = "linear-gradient(360deg, #F5F6F8 3.79%, #F5F6F8 94.7%)";
export const grayGradient3 = "linear-gradient(0deg, #F5F6F8 17.31%, #FFFFFF 100%)";
export const grayGradient4 = "linear-gradient(0deg, #FFFFFF 0%, #F5F6F8 87.31%)";
export const grayGradient5 = "linear-gradient(227.83deg, #FFFFFF 1.35%, #F5F6F8 104.63%), #FFFFFF";
export const grayGradient6 = "linear-gradient(227.83deg, #FFFFFF 4.1%, #F5F6F8 100%), #FFFFFF";
export const grayGradient7 = "linear-gradient(360deg, #FFFFFF 0%, #F5F6F8 100%)";
export const grayGradient8 = "linear-gradient(180deg, #FFFFFF 0%, #F5F6F8 100%)";
export const grayGradient9 = "linear-gradient(180deg, #FFFFFF 0%, #F5F6F8 147.57%)";
export const blueGradient = "linear-gradient(0deg, #50B8F2 0%, #0073D9 100%)";
