import React from "react"

import styles from "./textArea.module.scss"

export default ({ value, placeholder, style, classStyle, name, onChange }) => {
  return  <textarea
            value={value}
            placeholder={placeholder}
            className={[styles.textArea, classStyle].join(' ')}
            style={style}
            name={name}
            onChange={onChange}/>
}

